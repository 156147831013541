import { Link, useLocation } from 'react-router-dom';

import { ROUTES_PANEL } from '@constants/routes';

import Logo from 'assets/images/logo_icon_two.png';
import { ReactComponent as MenuIcon } from 'assets/svgs/icons/texts/Menu.svg';
import { ReactComponent as Article } from 'assets/svgs/icons/texts/Article.svg';
import { useLayout } from 'contexts/LayoutContext';

import Menu from './Menu';
import ResponsiveMenu from './ResponsiveMenu';
import Title from './Title';
import Profile from './Profile';

import { HeaderWrap } from './styles';

import type { HeaderInterface } from './interface';

const Header = ({
    isOpenResponsiveSidebar,
    onOpenResponsiveSidebar,
    isOpenResponsiveMenu,
    onOpenResponsiveMenu,
}: HeaderInterface) => {
    const { pathname } = useLocation();

    const { breadcrumbs, title} = useLayout();

    const menus = [
        {
            active: ROUTES_PANEL.DASHBOARD.fullPath === pathname,
            label: 'Dashboard',
            link: ROUTES_PANEL.DASHBOARD.fullPath,
        },
        {
            active: ROUTES_PANEL.ACCOUNTS.fullPath === pathname,
            label: 'Contas',
            link: ROUTES_PANEL.ACCOUNTS.fullPath,
        },
        {
            active: ROUTES_PANEL.ACCOUNTS_TAGS.fullPath === pathname,
            label: 'Tags de Contas',
            link: ROUTES_PANEL.ACCOUNTS_TAGS.fullPath,
        },
        {
            active: ROUTES_PANEL.TRACKINGS.fullPath === pathname,
            label: 'Rastreamento',
            link: ROUTES_PANEL.TRACKINGS.fullPath,
        },
        {
            active: ROUTES_PANEL.PLANS.fullPath === pathname,
            label: 'Planos',
            link: ROUTES_PANEL.PLANS.fullPath,
        },
        {
            active: ROUTES_PANEL.GATEWAY_PAYMENTS.fullPath === pathname,
            label: 'Integração',
            link: ROUTES_PANEL.GATEWAY_PAYMENTS.fullPath,
        },
    ];

    return (
        <>
            <HeaderWrap>
                <button
                    className="bars-responsive-sidebar"
                    type="button"
                    onClick={() =>
                        onOpenResponsiveSidebar(!isOpenResponsiveSidebar)
                    }
                >
                    <MenuIcon />
                </button>

                <Link className="logo" to="/">
                    <img
                        alt="logo do agtor, é um círculo de cor amaralo com quatro sementes dentro na cor branca"
                        src={Logo}
                    />
                </Link>

                <Title
                    className="header-title"
                    title={title}
                    breadcrumbs={breadcrumbs}
                />


                <Menu menus={menus} />

                <Profile
                    name="Felipe Schons Nagel"
                    image="https://scontent.ffln3-1.fna.fbcdn.net/v/t1.18169-9/25399075_526118684421548_1190428586239840596_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeHOkxJ-Ix9LxWkH-bWQZIuGhDlS2KjTpISEOVLYqNOkhA-iePSxN3Eh4TV2ucpl2ZSl9qxs1AVwwcpQ9ot_TaNJ&_nc_ohc=xxn-8Tm9TOoAX8QW02L&_nc_ht=scontent.ffln3-1.fna&oh=00_AT-N0yO8gjV4o27ARh5nRpQEez3GYdP-OhiJuN62TF9-LQ&oe=62AEF89B"
                />

                <button
                    className="bars-responsive-menu"
                    type="button"
                    onClick={() => onOpenResponsiveMenu(!isOpenResponsiveMenu)}
                >
                    <Article />
                </button>
            </HeaderWrap>
            <ResponsiveMenu
                menus={menus}
                isOpenResponsiveMenu={isOpenResponsiveMenu}
            />
        </>
    );
};
export default Header;
