import { useCallback, useState } from 'react';
import {
    AiOutlineNotification,
    AiOutlineWhatsApp,
    AiOutlineEdit,
    AiOutlineUser,
    AiOutlineCrown,
} from 'react-icons/ai';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';

import { Badge, Button, InputText, Modal, Table } from 'components';
import { onErrorMessage, onSuccessMessage } from 'helpers';
import { sendUniquePushNotification } from 'services';
import { distanceToNow, maskCellphone } from 'utils';

import { ROUTES_PANEL, QUERIES } from '@constants';

import { UserInfoWrap, TrackingWrap } from './styles';

import type { IAAccount } from '@types';

const AccountTable = ({
    data,
    isLoading,
}: {
    data: any;
    isLoading?: boolean;
}) => {
    const theme = useTheme();

    const [accountSelected, setAccountSelected] = useState<
        IAAccount | undefined
    >();
    const [titleNotification, setTitleNotification] = useState('');
    const [textNotification, setTextNotification] = useState('');
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleOpenModalNotification = (account: IAAccount) => {
        setAccountSelected(account);
        setIsOpenModal(true);
    };

    const { mutate: sendPushNotification, isLoading: isLoadingNotification } =
        useMutation(
            () =>
                sendUniquePushNotification(
                    accountSelected?.id,
                    titleNotification,
                    textNotification
                ),
            {
                mutationKey: [QUERIES.PUSH_NOTIFICATION.send],
                onSuccess: (response) => {
                    onSuccessMessage('Notificações', response.message);

                    setIsOpenModal(false);
                },
                onError: (err) => onErrorMessage('Notificações', err),
            }
        );

    const cleanTexts = () => {
        setTitleNotification('');
        setTextNotification('');
    };

    const handleOpenWhatsApp = (number: string) => {
        window.open(`https://api.whatsapp.com/send?phone=55${number}`);
    };

    const userInfo = useCallback(
        ({ ownerLogin, name, id, integrationId }) => (
            <UserInfoWrap isPremium={integrationId}>
                <div className="photo">
                    {integrationId && (
                        <div className="premium-icon">
                            <AiOutlineCrown />
                        </div>
                    )}
                    <AiOutlineUser />
                </div>
                <div className="infos">
                    <span
                        className={integrationId ? 'name integrated' : 'name'}
                    >
                        {String(id).padStart(4, '0')} - {name}
                    </span>
                    <span className="login">{ownerLogin}</span>
                </div>
            </UserInfoWrap>
        ),
        []
    );

    const userTrackings = useCallback(
        (trackingCount: number) => (
            <TrackingWrap>
                <Badge variation={trackingCount > 0 ? 'secondary' : 'light'}>
                    {String(trackingCount).padStart(4, '0')}
                </Badge>
            </TrackingWrap>
        ),
        []
    );

    const userOption = useCallback(
        (account) => (
            <div>
                <Button
                    model="light"
                    variation="primary"
                    onClick={() => handleOpenModalNotification(account)}
                    isSquare
                >
                    <AiOutlineNotification size={theme.fontSizes[5]} />
                </Button>

                <Button
                    model="light"
                    variation="primary"
                    onClick={() => handleOpenWhatsApp(account.ownerPhone)}
                    style={{ marginLeft: theme.spacing[2] }}
                    isSquare
                >
                    <AiOutlineWhatsApp size={theme.fontSizes[5]} />
                </Button>
                <Button
                    model="base"
                    variation="primary"
                    href={`${ROUTES_PANEL.ACCOUNTS.FORM.fullPath}/${account.id}`}
                    isSquare
                    style={{ marginLeft: theme.spacing[2] }}
                >
                    <AiOutlineEdit size={theme.fontSizes[5]} />
                </Button>
            </div>
        ),
        []
    );

    return (
        <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            <Table
                isLoading={isLoading}
                columns={[
                    {
                        dataField: 'name',
                        text: 'Nome',
                        sort: true,
                        style: { width: '100%' },
                        formatter: (_value, _index, row) => userInfo(row),
                    },
                    {
                        dataField: 'trackingCount',
                        text: 'Tracking',
                        style: { minWidth: '3rem' },
                        formatter: (value) =>
                            userTrackings(value),
                        sort: true,
                    },
                    {
                        dataField: 'ownerPhone',
                        text: 'Telefone',
                        style: { minWidth: '12.5rem' },
                        formatter: (value) => maskCellphone(value),
                    },
                    {
                        dataField: 'createdAt',
                        text: 'Criado',
                        style: { minWidth: '12.5rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'lastTrackingAt',
                        text: 'Acessado',
                        style: { minWidth: '12.5rem' },
                        formatter: (value) => distanceToNow(value),
                        sort: true,
                    },
                    {
                        dataField: 'id',
                        text: 'Opções',
                        style: { minWidth: '13rem' },
                        formatter: (_value, _index, row) => userOption(row),
                    },
                ]}
                data={data}
                style={{
                    marginTop: theme.spacing[4],
                }}
            />

            <Modal
                title="Nova Notificação"
                subTitle={`Será enviada para ${accountSelected?.name}`}
                isOpen={isOpenModal}
                onCloseModal={() => setIsOpenModal(false)}
                footer={
                    <>
                        <Button
                            model="light"
                            onClick={cleanTexts}
                            style={{ marginRight: theme.spacing[4] }}
                        >
                            Limpar
                        </Button>

                        <Button
                            isLoading={isLoadingNotification}
                            onClick={sendPushNotification}
                        >
                            Enviar
                        </Button>
                    </>
                }
            >
                <InputText
                    label="Título da Notificação"
                    name="title-notification"
                    onChange={setTitleNotification}
                    value={titleNotification}
                    placeholder="Informe o título da notificação!"
                    style={{ marginBottom: theme.spacing[8] }}
                    isRequired
                />

                <InputText
                    label="Texto da Notificação"
                    name="text-notification"
                    onChange={setTextNotification}
                    value={textNotification}
                    placeholder="Informe o texto da notificação!"
                    isRequired
                />
            </Modal>
        </div>
    );
};

export default AccountTable;
